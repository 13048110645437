import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import SendIcon from '@mui/icons-material/Send';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from '../context/AuthContext';
import tokenHorsePermissionsService from '../services/tokenHorsePermissionsService';

const predefinedPermissions = [
  'handleBookings',
  'handleVaccine',
  'handleFarrier',
  'handleVeterinarian',
  'handleHorseProfile',
];

const PermissionSelectionModal = ({ open, onClose, onSubmit, horseId }) => {
  const { t } = useTranslation();
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [inviteLink, setInviteLink] = useState('');
  const [showCheckboxes, setShowCheckboxes] = useState(true);
  const [loading, setLoading] = useState(false);
  const { apiKey, token, organizationId } = useAuth();

  const handlePermissionChange = (permission) => {
    setSelectedPermissions((prevSelected) =>
      prevSelected.includes(permission)
        ? prevSelected.filter((perm) => perm !== permission)
        : [...prevSelected, permission]
    );
  };

  const clearPermissionsOnClose = () => {
    onClose();
    setSelectedPermissions([]);
    setShowCheckboxes(true);
    setInviteLink('');
  };

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedPermissions(predefinedPermissions);
    } else {
      setSelectedPermissions([]);
    }
  };

  const handleGenerateInviteLink = async () => {
    try {
      setLoading(true);
      const data = await tokenHorsePermissionsService.generateInviteLink(
        horseId,
        selectedPermissions,
        apiKey,
        organizationId,
        token
      );
      const link = `${window.location.origin}/invite?token=${data.token}`;
      setInviteLink(link);
      setShowCheckboxes(false);
    } catch (error) {
      console.error('Error generating invite link:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendInvite = async () => {
    await handleGenerateInviteLink();
    // You can call onSubmit(selectedPermissions) here if needed.
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(inviteLink);
  };

  return (
    <Dialog open={open} onClose={clearPermissionsOnClose}>
      <DialogTitle>
        {t('PermissionsTab.addPermission')}
        <IconButton
          aria-label="close"
          onClick={clearPermissionsOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading && <LinearProgress />}
        <FormControl component="fieldset" fullWidth>
          {showCheckboxes && (
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '16px',
                backgroundColor: '#f9f9f9',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px'
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedPermissions.length === predefinedPermissions.length}
                    indeterminate={
                      selectedPermissions.length > 0 &&
                      selectedPermissions.length < predefinedPermissions.length
                    }
                    onChange={handleSelectAllChange}
                    sx={{
                      '&.Mui-checked': {
                        color: 'primary.main',
                      },
                    }}
                  />
                }
                label={
                  <Typography sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    {t('common.selectAll')}
                  </Typography>
                }
              />
              {predefinedPermissions.map((permission) => (
                <FormControlLabel
                  key={permission}
                  control={
                    <Checkbox
                      checked={selectedPermissions.includes(permission)}
                      onChange={() => handlePermissionChange(permission)}
                      sx={{
                        '&.Mui-checked': {
                          color: 'secondary.main',
                        },
                      }}
                    />
                  }
                  label={t(`PermissionsTab.${permission}`)}
                />
              ))}
            </Box>
          )}
          {inviteLink && (
            <Box mt={2} display="flex" flexDirection="column" alignItems="center">
              <IconButton
                aria-label="back"
                onClick={() => { setShowCheckboxes(true); setInviteLink(''); }}
                sx={{
                  position: 'absolute',
                  left: 8,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="subtitle1">{t('PermissionsTab.inviteLink')}</Typography>
              <Box display="flex" alignItems="center" mt={1} mb={2}>
                <Button
                  variant="text"
                  startIcon={<LinkIcon />}
                  endIcon={<FileCopyIcon />}
                  onClick={handleCopyLink}
                >
                  {t('PermissionsTab.copyLink')}
                </Button>
              </Box>
              <QRCode value={inviteLink} size={200} />
              <Box mt={2} display="flex" flexDirection="column" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SendIcon />}
                  onClick={() => window.open(`sms:?&body=${encodeURIComponent(inviteLink)}`, '_blank')}
                  sx={{ mb: 1 }}
                >
                  {t('PermissionsTab.sendSMS')}
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<WhatsAppIcon />}
                  onClick={() => window.open(`https://wa.me/?text=${encodeURIComponent(inviteLink)}`, '_blank')}
                >
                  {t('PermissionsTab.sendWhatsApp')}
                </Button>
              </Box>
            </Box>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={clearPermissionsOnClose} color="primary">
          {t('common.cancel')}
        </Button>
        <Button onClick={handleSendInvite} color="primary" disabled={loading}>
          {t('common.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PermissionSelectionModal;

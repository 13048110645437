// src/components/Bars/Sidebar.js
import React, { useState, useEffect, useRef } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Button,
  Popover,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import {
  Home as HomeIcon,
  Settings as SettingsIcon,
  Group as GroupIcon,
  LocalHospital as LocalHospitalIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
  FitnessCenter as FitnessCenterIcon,
  Vaccines as VaccinesIcon,
  Receipt as ReceiptIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Inventory as InventoryIcon,
  DocumentScanner as DocumentScannerIcon,
  HowToVote as HowToVoteIcon,
} from '@mui/icons-material';
import { GiHorseshoe } from 'react-icons/gi';
import { LiaHorseHeadSolid } from 'react-icons/lia';
import { FaWorm } from 'react-icons/fa6';
import { MdPlayLesson } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { usePermissions } from '../../context/PermissionsContext';
import { useAuth } from '../../context/AuthContext';
import { useDrawerState } from '../../context/DrawerContext';
import DrawerStates from './DrawerStates';

const IconWrapper = ({ icon: Icon, size = 24, color = 'inherit', style, ...props }) => {
  const iconStyle = { color, fontSize: size, ...style };
  return <Icon style={iconStyle} {...props} />;
};

const Sidebar = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userType, logout } = useAuth();
  const { instance } = useMsal();
  const drawerRef = useRef();
  const { entitlements } = usePermissions();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { drawerState, toggleDrawer, closeDrawer } = useDrawerState();
  const drawerWidth =
    drawerState === DrawerStates.OPEN ? 240 : drawerState === DrawerStates.PEEK ? 60 : 0;
  const isOpen = drawerState === DrawerStates.OPEN;
  const topBarHeight = isMobile ? '56px' : '64px';

  const [anchorEl, setAnchorEl] = useState(null);

  // Extract features from entitlements
  const features = entitlements
    .map((entitlement) => entitlement.features)
    .flat()
    .filter((feature) => feature.status === 'enabled');

  const hasFeature = (featureName) => {
    return features.some((feature) => feature.name === featureName);
  };

  const sidebarItems = [
    {
      label: t('bars.sideBar.home'),
      icon: <HomeIcon />,
      route: '/',
      requiredFeatures: [],
      dividerBefore: false,
    },
    {
      label: t('bars.sideBar.owners'),
      icon: <GroupIcon />,
      route: '/owners',
      requiredFeatures: ['owner-management'],
      userTypes: ['admin'],
      dividerBefore: true,
    },
    {
      label: t('bars.sideBar.salesProcess'),
      icon: <ReceiptIcon />,
      route: '/sales-process',
      requiredFeatures: ['sales-process'],
      userTypes: ['admin'],
      dividerBefore: false,
    },
    {
      label: t('bars.sideBar.expenses'),
      icon: <AccountBalanceWalletIcon />,
      route: '/expenses',
      requiredFeatures: ['expense-management'],
      userTypes: ['admin'],
      dividerBefore: false,
    },
    {
      label: t('bars.sideBar.inventoryManagement'),
      icon: <InventoryIcon />,
      route: '/inventory',
      requiredFeatures: ['inventory-management'],
      userTypes: ['admin'],
      dividerBefore: false,
    },
    {
      label: t('bars.sideBar.documents'),
      icon: <DocumentScannerIcon />,
      route: '/documents',
      requiredFeatures: ['document-management'],
      userTypes: ['admin'],
      dividerBefore: false,
    },
    {
      label: t('bars.sideBar.horses'),
      icon: <IconWrapper icon={LiaHorseHeadSolid} size={24} />,
      route: '/horses',
      requiredFeatures: ['horse-management'],
      userTypes: ['admin', 'customer', 'worker'],
      dividerBefore: true,
    },
    {
      label: t('bars.sideBar.guidingMachine'),
      icon: <FitnessCenterIcon />,
      route: '/guidingmachine',
      requiredFeatures: ['machine-management'],
      userTypes: ['admin', 'customer', 'worker'],
      dividerBefore: false,
    },
    {
      label: t('bars.sideBar.Bookings'),
      icon: <IconWrapper icon={MdPlayLesson} size={24} />,
      route: '/bookings',
      requiredFeatures: ['booking-management'],
      userTypes: ['admin'],
      dividerBefore: false,
    },
    {
      label: t('bars.sideBar.horseActivityVoting'),
      icon: <HowToVoteIcon />,
      route: '/horseActivity',
      requiredFeatures: ['horse-activity-voting'],
      userTypes: ['admin', 'customer', 'worker'],
      dividerBefore: false,
    },
    {
      label: t('bars.sideBar.farrierVisits'),
      icon: <IconWrapper icon={GiHorseshoe} size={24} />,
      route: '/farrier-visits',
      requiredFeatures: ['farrier-management'],
      userTypes: ['admin', 'customer', 'worker'],
      dividerBefore: true,
    },
    {
      label: t('bars.sideBar.veterinarianVisits'),
      icon: <LocalHospitalIcon />,
      route: '/veterinarian-visits',
      requiredFeatures: ['veterinarian-management'],
      userTypes: ['admin', 'customer', 'worker'],
      dividerBefore: false,
    },
    {
      label: t('bars.sideBar.vaccinations'),
      icon: <VaccinesIcon />,
      route: '/vaccinations',
      requiredFeatures: ['vaccination-management'],
      userTypes: ['admin', 'customer', 'worker'],
      dividerBefore: false,
    },
    {
      label: t('bars.sideBar.dewormings'),
      icon: <IconWrapper icon={FaWorm} size={24} />,
      route: '/dewormings',
      requiredFeatures: ['deworming-management'],
      userTypes: ['admin'],
      dividerBefore: false,
    },
  ];


  const activeStyle = {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.selected,
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Close drawer when location changes
  useEffect(() => {
    if (isMobile && drawerState !== DrawerStates.CLOSED) {
      closeDrawer();
    }
  }, [location, isMobile]);

  const useOutsideClick = (ref, callback, excludeSelector) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !event.target.closest(excludeSelector)
        ) {
          callback();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, callback, excludeSelector]);
  };

  useOutsideClick(drawerRef, () => {
    if (isMobile && drawerState === DrawerStates.OPEN) {
      closeDrawer();
    }
  }, '.topbar-icon-button');

  const handleLogout = () => {
    instance
      .logoutRedirect()
      .then(() => {
        logout();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <Drawer
      ref={drawerRef}
      variant="permanent"
      open={isOpen}
      sx={{
        overflowX: 'hidden',
        overflowY: 'auto',
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'fixed',
          zIndex: isOpen ? theme.zIndex.drawer + 2 : theme.zIndex.drawer,
          marginTop: topBarHeight,
          height: `calc(100% - ${topBarHeight})`,
          bgcolor: 'background.default',
        },
        [`& .MuiListItemIcon-root`]: {
          minWidth: '32px',
        },
      }}
    >
      <List sx={{ flexGrow: 1 }}>
        {sidebarItems.map((item, index) => {
          const hasAccess =
            (item.requiredFeatures.length === 0 || item.requiredFeatures.every(hasFeature)) &&
            (!item.userTypes || item.userTypes.includes(userType));

          if (!hasAccess) return null;

          return (
            <React.Fragment key={item.route}>
              {item.dividerBefore && <Divider />}
              <ListItem
                button
                component={NavLink}
                to={item.route}
                style={({ isActive }) => (isActive ? activeStyle : {})}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                {isOpen && <ListItemText primary={item.label} />}
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>


      {/* Language Switcher */}
      <Box
        sx={{
          alignSelf: 'flex-end',
          pb: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button onClick={handleClick} sx={{ width: '100%', justifyContent: 'center' }}>
          {i18n.language.toUpperCase()}
        </Button>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Button onClick={() => changeLanguage('en')}>EN</Button>
            <Button onClick={() => changeLanguage('de')}>DE</Button>
          </Box>
        </Popover>
      </Box>

      {/* Settings and Logout */}
      <List
        sx={{
          width: '100%',
          alignSelf: 'flex-end',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {userType === 'admin' && (
          <ListItem
            button
            component={NavLink}
            to="/organization-settings"
            sx={{ justifyContent: 'center', minWidth: '100%' }}
            style={({ isActive }) => (isActive ? activeStyle : {})}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            {isOpen && (
              <ListItemText primary={t('bars.sideBar.organizationSettings')} sx={{ textAlign: 'center' }} />
            )}
          </ListItem>
        )}
        <ListItem button onClick={handleLogout} sx={{ justifyContent: 'center', minWidth: '100%' }}>
          <ListItemIcon>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          {isOpen && <ListItemText primary="Logout" sx={{ textAlign: 'center' }} />}
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;

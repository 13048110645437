import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/salesOrders';

// Fetch all sales orders
export async function fetchAllSalesOrders(apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

// Fetch a single sales order by ID
export async function fetchSalesOrderById(apiKey, organizationId, jwtToken, id, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${id}&userId=${userId}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

// Save a new sales order
export async function saveSalesOrder(salesOrder, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}`;
  const response = await axiosInstance.post(url, salesOrder);
  return response.data;
}

// Update an existing sales order
export async function updateSalesOrder(salesOrder, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${salesOrder.id}&userId=${userId}`;
  const response = await axiosInstance.put(url, salesOrder);
  return response.data;
}

// Delete a sales order by ID
export async function deleteSalesOrder(salesOrderId, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${salesOrderId}&userId=${userId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

// Download a PDF version of a sales order
export async function downloadSalesOrderPdf(apiKey, organizationId, jwtToken, salesOrderId, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}/pdf?id=${salesOrderId}&userId=${userId}&action=getSalesOrderPDF`;
  const response = await axiosInstance.get(url, { responseType: 'blob' });
  return response.data;
}

// Send a PDF version of a sales order via email
export async function sendSalesOrderPdf(apiKey, organizationId, jwtToken, salesOrderId, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}/pdf?id=${salesOrderId}&userId=${userId}&action=sendSalesOrderPDF`;
  const response = await axiosInstance.get(url, { responseType: 'blob' });
  return response.data;
}

// Fetch all unsent sales orders
export async function fetchUnsentSalesOrders(apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}&status=unsent`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export default {
  fetchAllSalesOrders,
  fetchSalesOrderById,
  saveSalesOrder,
  updateSalesOrder,
  deleteSalesOrder,
  downloadSalesOrderPdf,
  sendSalesOrderPdf,
  fetchUnsentSalesOrders,
};

// src/components/AzureB2CAuthentication.js

import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Container, Typography, Button, Grid, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { loginRequest, passwordResetRequest } from '../context/authConfig';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';
import { renewToken } from '../context/tokenUtils';

const AzureB2CAuthentication = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { login, loginPostInformation, setIsLoggedIn } = useAuth();

  const handleLoginRedirect = () => {
    setIsLoading(true);
    console.log('Logging in...');
    instance
      .loginRedirect(loginRequest)
      .then((authResult) => {
        setIsLoading(false);
        login(authResult.accessToken);
        console.log('Login successful');
        navigate('/'); // Change this path to your post-login destination
      })
      .catch((error) => {
        if (error.errorMessage.indexOf('AADB2C90118') > -1) {
          handleForgotPassword();
        }
        console.error('Login error:', error);
        setIsLoading(false);
        // Add error handling code here
      });
  };

  const handleLoginPopup = () => {
    setIsLoading(true);
    console.log('Logging in with popup...');

    instance.loginPopup(loginRequest)
      .then((authResult) => {
        setIsLoading(false);
        console.log('Login successful');
        setIsLoggedIn(true);
        renewToken(instance, login, loginPostInformation, navigate);
        navigate('/');
      })
      .catch(error => {
        console.error('Login error:', error);
        setIsLoading(false);
        if (error.errorMessage.indexOf('AADB2C90118') > -1) {
          handleForgotPassword();
        }
        // Handle errors such as user cancellation or network issues
      });
  };

  const handleForgotPassword = () => {
    // Redirect to the forgotten password route of your application
    navigate('/forgot-password');
  };



  if (isLoading) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h6">{t('Authentication.waitingText')}</Typography>
        <LinearProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        {t('Authentication.title')}
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {/*<Grid item xs={12}>
          <Button fullWidth variant="contained" color="primary" onClick={handleLoginRedirect}>
            {t('Authentication.login')}
          </Button>
        </Grid>*/}
        <Grid item xs={12}>
          <Button fullWidth variant="contained" color="primary" onClick={handleLoginPopup}>
            {t('Authentication.login')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant="contained" color="secondary" onClick={handleForgotPassword}>
            {t('Authentication.forgotPassword')}
          </Button>
        </Grid>
        {/*<Grid item xs={12}>
          <Button variant="contained" color="secondary" onClick={handleLogout}>
            {t('Authentication.logout')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth onClick={handleForgotPassword}>
            {t('Authentication.forgotPassword')}
          </Button>
        </Grid>*/}
      </Grid>
    </Container>
  );
};

export default AzureB2CAuthentication;

import React from 'react';
import { Typography, Box, Paper } from '@mui/material';

const JsonDisplayComponent = ({ data, level = 0 }) => {
  const isObject = (val) => typeof val === 'object' && val !== null && !Array.isArray(val);

  const renderData = (key, value) => {
    return (
      <Box sx={{ ml: level * 2, mt: 1, mb: 1 }}>
        <Typography variant="body1"><strong>{key}:</strong> {value}</Typography>
      </Box>
    );
  };

  return (
    <Box>
      {Object.entries(data).map(([key, value]) => {
        if (isObject(value)) {
          return (
            <Box key={key}>
              {renderData(key, '')}
              <JsonDisplayComponent data={value} level={level + 1} />
            </Box>
          );
        } else if (Array.isArray(value)) {
          return (
            <Box key={key}>
              {renderData(key, 'Array')}
              {value.map((item, index) => (
                <Paper key={index} elevation={0} sx={{ ml: level * 2 + 2, mt: 1, mb: 1, p: 1, bgcolor: 'action.hover' }}>
                  {isObject(item) ? <JsonDisplayComponent data={item} level={level + 2} /> : renderData(index, item)}
                </Paper>
              ))}
            </Box>
          );
        } else {
          return renderData(key, value.toString());
        }
      })}
    </Box>
  );
};

export default JsonDisplayComponent;
// src/pages/DocumentsDetailsPage/useDocumentDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Paper, Button, Accordion, AccordionSummary, AccordionDetails, Modal, TextField, CircularProgress, LinearProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useDocumentDöetails from './useDocumentDetails';
import JsonDisplayComponent from './JsonDisplayComponent';
import { useTranslation } from 'react-i18next';
import ItemSettingsModal from '../../components/ItemSettingsModal';

const DocumentsDetailsPage = () => {
    const { documentId } = useParams();
    const { t } = useTranslation();
    const { documentDetails,
        deleteDocument,
        handleDownload,
        handleScan,
        handleTransferClick,
        handleShowStructuredData,
        handleShowItemSuggestion,
        handleItemScan,
        scanResult,
        isPageLoading,
        isScanLoading,
        openModal,
        setItemSuggestionModalOpen,
        itemSuggestionModalOpen,
        handleClose } = useDocumentDöetails(documentId);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const uploadDate = documentDetails.uploadDate ? new Date(documentDetails.uploadDate).toLocaleDateString("en-US") : 'N/A';

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            {isPageLoading && <LinearProgress />}
            <Accordion defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h6">{t('documentData.documentDetails.title')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box mb={2}>
                        <Typography variant="h6">{t('documentData.documentDetails.documentName')}: {documentDetails.name || documentDetails.orginalName}</Typography>
                        <Typography variant="body1">{t('documentData.documentDetails.description')}: {documentDetails.description || 'No description available.'}</Typography>
                        <Typography variant="body1">{t('documentData.documentDetails.documentType')}: {documentDetails.documentType}</Typography>
                        <Typography variant="body1">{t('documentData.documentDetails.businessType')}: {documentDetails.businessType || 'Not specified'}</Typography>
                        <Typography variant="body1">{t('documentData.documentDetails.uploadDate')}: {uploadDate}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Button variant="contained" color="primary" onClick={handleScan} disabled={isScanLoading || (documentDetails.businessType === 'vacination' || documentDetails.businessType === 'other')} >
                            {isScanLoading ? <CircularProgress size={24} /> : t('documentData.documentDetails.scanButton')}
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleItemScan} disabled={isScanLoading || (documentDetails.businessType === 'vacination' || documentDetails.businessType === 'other')} >
                            {isScanLoading ? <CircularProgress size={24} /> : t('documentData.documentDetails.scanItemsButton')}
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleShowItemSuggestion} disabled={isScanLoading}>
                            {t('documentData.documentDetails.showItemSuggestionButton')}
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleShowStructuredData} disabled={isScanLoading}>
                            {t('documentData.documentDetails.showStructuredDataButton')}
                        </Button>
                    </Box>

                    <Modal
                        open={openModal}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {t('documentData.documentDetails.scanResult')}
                            </Typography>

                            <JsonDisplayComponent data={scanResult} />
                        </Box>
                    </Modal>
                    <ItemSettingsModal
                        open={itemSuggestionModalOpen}
                        onClose={() => setItemSuggestionModalOpen(false)}
                        structuredItemSuggestion={documentDetails.structuredItemSuggestion}
                        onSave={(updatedSuggestion) => {
                            // Here you would handle updating the state or backend with the modified suggestions
                            console.log('Save structured item suggestion:', updatedSuggestion);
                            setItemSuggestionModalOpen(false);
                        }}
                    />
                </AccordionDetails>
            </Accordion>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
                <Button variant="contained" color="primary" onClick={handleDownload}>
                    {t('documentData.documentDetails.downloadButton')}
                </Button>
                <Button variant="contained" color="primary" onClick={handleTransferClick} disabled={isScanLoading || !documentDetails.structuredData || JSON.stringify(documentDetails.structuredData) === '{}' || documentDetails.structuredData === null}>
                    {t('documentData.documentDetails.transferButton')}
                </Button>
                <Button variant="contained" color="secondary" onClick={() => deleteDocument(documentDetails.id)}>
                    {t('documentData.documentDetails.deleteButton')}
                </Button>
            </Box>
            <Box mt={4}>
                <Typography variant="h6">{t('documentData.documentDetails.documentPreview')}</Typography>
                {
                    documentDetails.documentType.startsWith("image/") ?
                        <img src={`data:${documentDetails.documentType};base64,${documentDetails.base64}`} alt="Document Preview" style={{ maxWidth: '100%' }} /> :
                        documentDetails.documentType === "application/pdf" &&
                        <object data={`data:${documentDetails.documentType};base64,${documentDetails.base64}`} type={documentDetails.documentType} width="100%" height="500px">
                            <Typography variant="body1">{t('documentData.documentDetails.noPreview')}</Typography>
                        </object>
                }
            </Box>
        </Paper>
    );
};

export default DocumentsDetailsPage;

import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Snackbar, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import MuiAlert from '@mui/material/Alert';
import QRCode from 'qrcode.react';
import { useAuth } from '../context/AuthContext';
import { getTokenInviteOwners, refreshTokenInviteOwners } from '../services/ownerService';
import { useTranslation } from 'react-i18next';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

const InviteUserModal = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [linkCopied, setLinkCopied] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [inviteToken, setInviteToken] = useState('placeholderToken');
    const [expiry, setExpiry] = useState(null);
    const { apiKey, organizationId, token } = useAuth();

    const link = `${window.location.origin}/auth/signup?token=${inviteToken}&organizationId=${organizationId}&signuptype=guided`;

    useEffect(() => {
        if (isOpen) {
            fetchToken();
        }
    }, [isOpen, apiKey]);

    const fetchToken = async () => {
        try {
            setLoading(true);
            const fetchedToken = await getTokenInviteOwners(apiKey, organizationId, token);
            setInviteToken(fetchedToken.token);
            setExpiry(new Date(fetchedToken.expiry));
        } catch (error) {
            console.error("Error fetching the backendToken:", error);
        } finally {
            setLoading(false);
        }
    };

    const refreshInviteToken = async () => {
        try {
            setLoading(true);
            const refreshedToken = await refreshTokenInviteOwners(apiKey, organizationId, token);
            setInviteToken(refreshedToken.token);
            setExpiry(new Date(refreshedToken.expiry));
        } catch (error) {
            console.error("Error refreshing the backendToken:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(link);
        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), 3000);
    };

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: t('InviteOwnersButton.QRTitle'),
                    text: t('InviteOwnersButton.QRText'),
                    url: link,
                });
            } catch (error) {
                console.error('Something went wrong with sharing', error);
                setSnackbarOpen(true);
            }
        } else {
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason !== 'clickaway') {
            setSnackbarOpen(false);
        }
    };

    const getRemainingTime = () => {
        if (!expiry) return '';
        const now = new Date();
        const timeLeft = expiry - now;
        const hours = Math.floor(timeLeft / 1000 / 60 / 60);
        const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
        return `${hours}h ${minutes}m`;
    };

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} aria-labelledby="invite-dialog-title">
                <DialogTitle id="invite-dialog-title">{t('InviteOwnersButton.inviteNewOwners')}</DialogTitle>
                <DialogContent>
                    {loading ? <CircularProgress /> : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                            <QRCode value={link} size={256} />
                            <Button onClick={handleCopyToClipboard} variant="contained">
                                {t('InviteOwnersButton.copyToClipboard')}
                            </Button>
                            {linkCopied && <span>{t('InviteOwnersButton.linkCopied')}</span>}
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <div>{t('InviteOwnersButton.tokenValidFor')}{getRemainingTime()}</div>
                                <IconButton onClick={refreshInviteToken} aria-label="refresh token">
                                    <RefreshIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{t('InviteOwnersButton.close')}</Button>
                    <Button variant="outlined" onClick={handleShare}>{t('InviteOwnersButton.shareQRCode')}</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="info">
                    {t('InviteOwnersButton.browserShareSupport')}
                </Alert>
            </Snackbar>
        </>
    );
};

export default InviteUserModal;

import React, { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import SnackbarAlert from './SnackbarAlert';

function AuthRedirect() {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const location = useLocation();
    const { login } = useAuth();
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'info',
    });

    // Retrieve any state or query parameters
    const params = new URLSearchParams(location.search);
    const signUpType = params.get('signuptype') || 'default'; // Adjust as per your needs

    useEffect(() => {
        if (inProgress === 'none' && accounts.length > 0) {
            const account = accounts[0];

            instance.acquireTokenSilent({
                account,
                scopes: ['Permission.All'], // Adjust scopes as needed
            })
            .then((response) => {
                login(response.accessToken);
                let redirectPath = '/'; // Default path

                // Redirect based on the signup type
                switch (signUpType) {
                    case 'guided':
                        redirectPath = '/auth/guided-signup';
                        break;
                    case 'organization':
                        redirectPath = '/auth/organization-signup';
                        break;
                    // Add more cases as needed
                    default:
                        break;
                }
                navigate(redirectPath); // Adjust the navigation path as required
                setSnackbar({
                    open: true,
                    message: 'Authentication Successful!',
                    severity: 'success',
                });
            })
            .catch((error) => {
                console.error('Error acquiring token silently:', error);

                // Use redirect as the fallback method
                instance.acquireTokenRedirect({ scopes: ['Permission.All'] });
            });
        }
    }, [instance, accounts, inProgress, signUpType, navigate, login]);

    return (
        <div>
            {inProgress !== 'none' ? <h2>Authentication in progress...</h2> : null}
            <SnackbarAlert
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </div>
    );
}

export default AuthRedirect;

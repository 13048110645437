import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DashboardCard from './DashboardCard';
import FinancialCard from './FinancialCard';
import ExpenseBarChart from './ExpenseBarChart';
import IncomeBarChart from './IncomeBarChart';
import Last12MonthsLineChart from './Last12MonthsLineChart';
import { useDashboardData } from './useDashboardData';
import ItemsUsageLineChart from './ItemsUsageLineChart';
import GenericCardListView from './GenericCardListView';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import BusiestTimesLineChart from './BusiestTimesLineChart';
import { usePermissions } from '../../../context/PermissionsContext'; // <-- Add this line

const AdminDashboard = ({ apiKey, organizationId, jwtToken }) => {
    const { t, i18n } = useTranslation();
    const { userId } = useAuth();
    const { entitlements } = usePermissions();
    const [selectedDate, setSelectedDate] = useState(new Date());

    const { internalName } = entitlements.find(entitlement => entitlement.isActive) || {};

    const isEquistab = internalName.includes("equistab");
    const isErp = internalName.includes("erp");

    const {
        horseCount, ownerCount, coRiderCount, todayBookingCount,
        monthlyExpenses, monthlyIncome, financialStatus, taxStatus,
        currentMonthStackedExpenses, currentMonthIncomeByCategory,
        last12MonthsData, translatedExpenseKey, translatedIncomeKey, activeSubcategories, last30DaysItemUsageData,
        warningItems, services, busiestTimes, busiestTimePrevDay, busiestTimeNextDay, busiestTimesDate
    } = useDashboardData(apiKey, organizationId, jwtToken, userId, selectedDate.getMonth(), selectedDate.getFullYear(), isEquistab);

    const handlePrevMonth = () => {
        const newDate = new Date(selectedDate.setMonth(selectedDate.getMonth() - 1));
        setSelectedDate(newDate);
    };

    const handleNextMonth = () => {
        const newDate = new Date(selectedDate.setMonth(selectedDate.getMonth() + 1));
        setSelectedDate(newDate);
    };

    const localeMap = {
        en: enUS,
        // Add other locales here as needed
    };

    const selectedLocale = localeMap[i18n.language] || enUS;
    const selectedMonthName = format(selectedDate, 'MMMM yyyy', { locale: selectedLocale });

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                {t('Home.AdminDashboard.dashboardTitle')}
            </Typography>
            <Grid container spacing={3}>
                {isEquistab && (
                    <>
                        <DashboardCard titleKey='Home.AdminDashboard.todayBookingCount' count={todayBookingCount} onClick="/guidingMachine" />
                        <DashboardCard titleKey='Home.AdminDashboard.horseCount' count={horseCount} onClick="/horses" />
                        <DashboardCard titleKey='Home.AdminDashboard.ownerCount' count={ownerCount} onClick="/owners" />
                        <DashboardCard titleKey='Home.AdminDashboard.coRiderCount' count={coRiderCount} />
                    </>
                )}

                {/* Common elements for both Equistab and ERP */}
                <FinancialCard titleKey='Home.AdminDashboard.monthlyExpenses' amount={monthlyExpenses} />
                <FinancialCard titleKey='Home.AdminDashboard.monthlyIncome' amount={monthlyIncome} />
                <FinancialCard titleKey='Home.AdminDashboard.financialStatus' amount={financialStatus} />
                <FinancialCard titleKey='Home.AdminDashboard.taxStatus' amount={taxStatus} />
                <>

                    <Box display="flex" alignItems="center" justifyContent="center" width="100%" my={2}>
                        <IconButton onClick={handlePrevMonth}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6">
                            {selectedMonthName}
                        </Typography>
                        <IconButton onClick={handleNextMonth}>
                            <ArrowForwardIcon />
                        </IconButton>
                    </Box>

                    <ExpenseBarChart titleKey='Home.AdminDashboard.expense' data={currentMonthStackedExpenses} bars={activeSubcategories} />
                    <IncomeBarChart titleKey='Home.AdminDashboard.income' data={currentMonthIncomeByCategory} />
                    <Last12MonthsLineChart
                        titleKey='Home.AdminDashboard.last12MonthsExpensesAndIncome'
                        data={last12MonthsData}
                        expenseKey={translatedExpenseKey}
                        incomeKey={translatedIncomeKey}
                    />
                    <ItemsUsageLineChart
                        titleKey='Home.AdminDashboard.last30DaysItemUsage'
                        data={last30DaysItemUsageData}
                    />
                </>

                {isEquistab && (
                    <>
                        <GenericCardListView
                            title={t('Home.AdminDashboard.warningItems')}
                            data={warningItems.data}
                            columns={warningItems.columns}
                        />
                        <GenericCardListView
                            title={t('Home.AdminDashboard.services')}
                            data={services.data}
                            columns={services.columns}
                        />
                        <BusiestTimesLineChart
                            titleKey='Home.AdminDashboard.busiestTimes'
                            data={busiestTimes}
                            date={busiestTimesDate}
                            prevDay={busiestTimePrevDay}
                            nextDay={busiestTimeNextDay}
                        />
                    </>
                )}
            </Grid>
        </Box>
    );
};


export default AdminDashboard;

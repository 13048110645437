// src/services/BusinessDocumentService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/approvalService';


export async function approveDocument(apiKey, organizationId, token, userId, documentId, documentType) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(token, userId);
    const response = await axiosInstance.put(`${API_BASE_URL}?id=${documentId}&documentType=${documentType}&status=approve`);
    return response.data;
}

export async function rejectDocument(apiKey, organizationId, token, userId, documentId, documentType) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(token, userId);
    const response = await axiosInstance.put(`${API_BASE_URL}?id=${documentId}&documentType=${documentType}&status=reject`);
    return response.data;
}

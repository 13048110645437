import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { PermissionsProvider } from './context/PermissionsContext';
import AppRoutes from './Routes/AppRoutes';

// MSAL imports 
import { MsalProvider } from "@azure/msal-react";
import { setPolicyBasedOnUrl } from './context/setPolicyBasedOnUrl'; // Adjust the path as needed

const msalInstance = setPolicyBasedOnUrl(); // Initialize MSAL instance with dynamic policy

function AppContent() {
  return (
    <AppRoutes />
  );
}

function App() {
  return (
    <PermissionsProvider>
      <AuthProvider>
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            <AppContent />
          </BrowserRouter>
        </MsalProvider>
      </AuthProvider>
    </PermissionsProvider>
  );
}

export default App;

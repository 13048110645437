// src/components/VaccinationModal.js
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Switch,
} from '@mui/material';
import { saveVaccination, updateVaccination, deleteVaccination } from '../services/VaccinationService';  // Import the service methods
import { useAuth } from '../context/AuthContext';  // To get the authentication tokens and IDs
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { useDateLocale } from '../hooks/useDateLocale';
import { format } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getAllHorses, getHorsesByOwnerID } from '../services/horseService';
import { fetchAllVeterinarians } from '../services/VeterinarianService';
import SnackbarAlert from './SnackbarAlert'; // Adjust the path as necessary


const VaccinationModal = ({ isOpen, onClose, record, horses = [], vets = [] }) => {

    const { apiKey, organizationId, token, ownerId, userType } = useAuth();
    const [horseRecords, setHorseRecords] = useState([]);
    const [vetRecords, setVetRecords] = useState([]);
    const { t } = useTranslation();
    const dateLocale = useDateLocale();
    const [formData, setFormData] = useState({
        id: '',
        horseId: '',
        vaccinationName: '',
        vaccinationDate: '',
        nextVaccinationDate: '',
        notifyOwner: false,
        shareWithOwner: true,
        vetId: ''
    });
    const vaccinationTypesMapping = {
        "Tetanus": t('Vaccination.Types.Tetanus'),
        "EquineInfluenza": t('Vaccination.Types.EquineInfluenza'),
        "EquineHerpesvirus": t('Vaccination.Types.EquineHerpesvirus'),
        "WestNileVirus": t('Vaccination.Types.WestNileVirus'),
        "Rabies": t('Vaccination.Types.Rabies'),
        "EquineViralArteritis": t('Vaccination.Types.EquineViralArteritis'),
        "Rotavirus": t('Vaccination.Types.Rotavirus')
    };
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // Can be 'error', 'warning', 'info', or 'success'
    });
    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    useEffect(() => {
        if (record) {
            setFormData({
                ...record,
                id: record.id,
                horseId: record.horseId,
                vetId: record.vetId,
                notifyOwner: record.notifyOwner ? true : false,
                shareWithOwner: record.shareWithOwner ?? true,
                vaccinationDate: record.vaccinationDate ? new Date(record.vaccinationDate).toISOString() : null,
                nextVaccinationDate: record.nextVaccinationDate ? new Date(record.nextVaccinationDate).toISOString() : null,

            });
        } else {
            setFormData({
                id: uuidv4(),
                horseId: '',
                vaccinationName: '',
                vaccinationDate: '',
                nextVaccinationDate: '',
                notifyOwner: record ? record.notifyOwner : false,
                shareWithOwner: true,
                vetId: ''
            });
        }

        const fetchData = async () => {
            const fetchedHorses = horses.length > 0 ? horses : userType === 'customer' ? await getHorsesByOwnerID(ownerId, apiKey, organizationId, token) : await getAllHorses(apiKey, organizationId, token);
            const fetchedVets = vets.length > 0 ? vets : await fetchAllVeterinarians(apiKey, organizationId, token);
            setHorseRecords(fetchedHorses.filter(horse => horse.permissions?.includes('handleVaccine')));
            setVetRecords(fetchedVets);
        };
        fetchData();

    }, [record]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };
    const handleDateChange = (name, value) => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prev) => ({ ...prev, [name]: checked }));
    };

    const handleSwitchChange = (event) => {
        setFormData({ ...formData, shareWithOwner: event.target.checked });
    };

    const handleSubmit = async () => {
        try {
            // Prepare data with ISO-formatted dates
            const dataToSave = {
                ...formData,
                vaccinationDate: formData.vaccinationDate ? new Date(formData.vaccinationDate).toISOString() : null,
                nextVaccinationDate: formData.nextVaccinationDate ? new Date(formData.nextVaccinationDate).toISOString() : null
            };

            if (record) {
                await updateVaccination(dataToSave, apiKey, organizationId, token, ownerId);
            } else {
                await saveVaccination(dataToSave, apiKey, organizationId, token, ownerId);
            }
            onClose();  // Close the dialog after saving data
            setSnackbar({
                open: true,
                message: t('Vaccination.Dialog.saveSuccess'),
                severity: 'success',
            });
        } catch (error) {
            // Show error message
            setSnackbar({
                open: true,
                message: t('Vaccination.Dialog.saveError'),
                severity: 'error',
            });
        }
    };

    const handleDelete = async () => {
        try {
            if (record && record.id) {
                await deleteVaccination(record.id, apiKey, organizationId, token, ownerId);
                onClose();  // Close the dialog after deleting
                setSnackbar({
                    open: true,
                    message: t('Vaccination.Dialog.deleteSuccess'),
                    severity: 'success',
                });
            }
        } catch (error) {
            // Show error message
            setSnackbar({
                open: true,
                message: t('Vaccination.Dialog.deleteError'),
                severity: 'error',
            });
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>
                    {t('Vaccination.Dialog.Title')}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>{t('Vaccination.Dialog.HorseName')}</InputLabel>
                        <Select
                            name="horseId"
                            value={formData.horseId}
                            onChange={handleChange}
                        >
                            {horseRecords.map((horse) => (
                                <MenuItem key={horse.id} value={horse.id}>{horse.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>{t('Vaccination.Dialog.vaccinationName')}</InputLabel>
                        <Select
                            name="vaccinationName"
                            value={formData.vaccinationName}
                            onChange={handleChange}
                        >
                            {Object.entries(vaccinationTypesMapping).map(([key, value]) => (
                                <MenuItem key={key} value={key}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <DatePicker
                            label={t('Vaccination.Dialog.vaccinationDate')}
                            value={formData.vaccinationDate ? new Date(formData.vaccinationDate) : null}
                            onChange={(newValue) => handleDateChange('vaccinationDate', newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <DatePicker
                            label={t('Vaccination.Dialog.nextVaccinationName')}
                            value={formData.nextVaccinationDate ? new Date(formData.nextVaccinationDate) : null}
                            onChange={(newValue) => handleDateChange('nextVaccinationDate', newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </FormControl>
                    <Select
                        label={t('Vaccination.Dialog.vetsName')}
                        name="vetId"
                        value={formData.vetId}
                        onChange={handleChange}
                        fullWidth
                    >
                        {vetRecords.map((vet) => (
                            <MenuItem key={vet.id} value={vet.id}>{vet.firstname} {vet.surname}</MenuItem>
                        ))}
                    </Select>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.shareWithOwner}
                                onChange={handleSwitchChange}
                                name="shareWithOwner"
                            />
                        }
                        label={t('Vaccination.Dialog.shareWithOwner')}
                    />
                    <FormControl fullWidth margin="normal">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.notifyOwner}
                                        onChange={handleCheckboxChange}
                                        name="notifyOwner"
                                    />
                                }
                                label={t('Vaccination.Dialog.NotifyOwner')}
                            />
                        </FormGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    {record && (
                        <Button variant="outlined" color="error" onClick={handleDelete}>
                            {t('Vaccination.Dialog.delete')}
                        </Button>
                    )}
                    <Button variant="outlined" color="secondary" onClick={onClose}>
                        {t('Vaccination.Dialog.cancel')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        {t('Vaccination.Dialog.save')}
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackbarAlert
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </LocalizationProvider>
    );
};

export default VaccinationModal;
// src/components/OrgTabs/UserForm.js

import React, { useState, useEffect } from 'react';
import {
    TextField, Button, Paper, Container, FormControlLabel, Switch,
    Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { getUserById, createUser, updateUser, deleteUser, inviteUserByEmail } from '../../services/userService'; // Implement these services
import { useAuth } from '../../context/AuthContext';
import LinearProgress from '@mui/material/LinearProgress';

const UserForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const mode = id ? 'edit' : 'add';
    const { apiKey, token, organizationId } = useAuth();
    const [loading, setLoading] = useState(false);
    const roleOptions = [
        { value: 'admin', label: 'Admin' },
        { value: 'customer', label: 'Customer' },
        { value: 'worker', label: 'Worker' },
        { value: 'teacher', label: 'Teacher' }
    ];

    const statusOptions = [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
        { value: 'invited', label: 'Invited' }
    ];

    const [userData, setUserData] = useState({
        userId: '',
        firstname: '',
        surname: '',
        Password: '',
        Email: '',
        Role: '', // Add other relevant fields
        status: false,
    });

    const isSwitchDisabled = mode === 'add';

    useEffect(() => {
        if (mode === 'edit') {
            setLoading(true);
            getUserById(id, apiKey, organizationId, token)
                .then(data => {
                    setUserData(data);
                })
                .catch(err => console.error("Failed to fetch user by ID:", err))
                .finally(() => setLoading(false));
        }
    }, [mode, id, apiKey, organizationId, token]);

    // Function to handle sending another invite
    const handleSendInvite = async () => {
        if (userData.userId && userData.status === 'invited') {
            // Logic to send another invite
            // This should call a service function to send the invite
            console.log('Sending another invite to user:', userData.userId);
            await inviteUserByEmail(userData.userId, apiKey, organizationId, token);
        }
    };


    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };

    const handleStatusChange = (event) => {
        setUserData({
            ...userData,
            status: event.target.value,
        });
    };

    const handleActiveChange = (event) => {
        setUserData({
            ...userData,
            active: event.target.checked,
        });
    };

    const handleSaveAndInvite = (event) => {
        event.preventDefault();
        setLoading(true);
        
        const userDataToSend = {
            // Structure your user data as required by your API
            firstname: userData.firstname,
            surname: userData.surname,
            Password: '',
            Email: userData.Email,
            Role: userData.Role,
            status: userData.status,
        };

        createUser(userDataToSend, apiKey, organizationId, token)
            .then(() => {
                inviteUserByEmail(userDataToSend.Email, apiKey, organizationId, token);
                navigate('/organization-settings?tab=7');
            })
            .catch(err => {
                console.error('Failed to save or update user:', err);
            })
            .finally(() => setLoading(false));
        };


    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const userDataToSend = {
            // Structure your user data as required by your API
            firstname: userData.firstname,
            surname: userData.surname,
            Email: userData.Email,
            Role: userData.Role,
            status: userData.status,
        };

        // Determine whether to create or update based on 'mode'
        const actionPromise = mode === 'edit'
            ? updateUser(id, userDataToSend, apiKey, organizationId, token)
            : createUser(userDataToSend, apiKey, organizationId, token);

        actionPromise
            .then(() => {
                navigate('/organization-settings?tab=7');
            })
            .catch(err => {
                console.error('Failed to save or update user:', err);
            })
            .finally(() => setLoading(false));
    };


    const handleDelete = () => {
        deleteUser(id, apiKey, organizationId, token)
            .then(() => {
                navigate('/organization-settings?tab=7');
            })
            .catch(err => {
                console.error('Failed to delete user:', err);
            });
    };

    return (
        <Container component={Paper} style={{ padding: '2em' }}>
            {loading && <LinearProgress />}
            <h1>{mode === 'add' ? 'Add New User' : 'Edit User'}</h1>
            <form onSubmit={handleSubmit}>
                {/* Add fields for user data here */}
                <TextField
                    name="firstname"
                    label="First Name"
                    variant="outlined"
                    margin="normal"
                    value={userData.firstname}
                    onChange={handleChange}
                    fullWidth
                />
                <TextField
                    name="surname"
                    label="Surname"
                    variant="outlined"
                    margin="normal"
                    value={userData.surname}
                    onChange={handleChange}
                    fullWidth
                />
                <TextField
                    name="Email"
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    value={userData.Email}
                    onChange={handleChange}
                    fullWidth
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="role-select-label">Role</InputLabel>
                    <Select
                        labelId="role-select-label"
                        id="role-select"
                        name="Role"
                        value={userData.Role}
                        label="Role"
                        onChange={handleChange}
                    >
                        {roleOptions.map((option, index) => (
                            <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="status-select-label">Status</InputLabel>
                    <Select
                        labelId="status-select-label"
                        id="status-select"
                        name="status"
                        value={userData.status}
                        label="Status"
                        onChange={handleStatusChange}
                        disabled={isSwitchDisabled}
                    >
                        {statusOptions.map((option, index) => (
                            <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {mode === 'add' && (

                    <Button variant="contained" color="primary" sx={{ marginRight: 2 }} onClick={handleSaveAndInvite}>
                        Save and Invite
                    </Button>
                )}
                {mode === 'edit' && (
                    <>
                        <Button type="submit" variant="contained" color="primary" sx={{ marginRight: 2 }}>
                            Save
                        </Button>
                        <Button variant="contained" color="error" onClick={handleDelete} sx={{ marginRight: 2 }}>
                            Delete
                        </Button>
                        {userData.status === 'invited' && (
                            <Button variant="contained" onClick={handleSendInvite}>
                                Send Another Invite
                            </Button>
                        )}
                    </>
                )}
            </form>
        </Container>
    );
};

export default UserForm;

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Button,
  Collapse,
  Box,
  Typography
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';
import CreateIcon from '@mui/icons-material/Create'; // Icon for manual input
import ListIcon from '@mui/icons-material/List'; // Icon for popup item selection
import ItemSelectionPopup from '../../components/ItemSelectionPopup'; // Import the ItemSelectionPopup component

const InvoiceTable = ({ invoiceData, handleInputChange, removeRow, addRow, showAddRows, addBoxRow, addMachineRow, addServiceRow }) => {
  const { t } = useTranslation();
  const itemTypes = ['Manuell', 'Item', 'Box', 'Service', 'Device']; // Add 'Item' to the list of item types
  const [expandedRow, setExpandedRow] = useState(null);
  const [itemSelectionOpen, setItemSelectionOpen] = useState(false); // Manage popup state

  const getItemTypeTranslation = (type) => {
    return t(`invoiceDetailPage.itemTypes.${type.toLowerCase()}`);
  };

  const toggleRowExpansion = (index) => {
    setExpandedRow((prevExpandedRow) => (prevExpandedRow === index ? null : index));
  };
  const handleAddItem = (item) => {
    // Logic to add the selected item from the popup
    addRow({
      description: item.name,
      quantity: 1,
      type: 'Item',
      netPrice: item.netPrice,
      taxRate: item.taxPercentage,
      bruttoPrice: item.bruttoPrice,
      discountPercentage: 0,
      discountAmount: 0,
      rowTotal: item.bruttoPrice,
      itemId: item.id,
      organizationId: item.organizationId
    });
    setItemSelectionOpen(false); // Close the popup after adding an item
  };

  return (
    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('invoiceDetailPage.table.itemDescription')}</TableCell>
            <TableCell>{t('invoiceDetailPage.table.itemType')}</TableCell>
            <TableCell>{t('invoiceDetailPage.table.quantity')}</TableCell>
            <TableCell>{t('invoiceDetailPage.table.rowTotal')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceData?.rows?.map((row, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell>
                  <TextField
                    name="description"
                    value={row.description}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    name="type"
                    value={row.type}
                    onChange={(e) => handleInputChange(e, index)}
                    fullWidth
                  >
                    {itemTypes.map((type, idx) => (
                      <MenuItem key={idx} value={type}>{getItemTypeTranslation(type)}</MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    name="quantity"
                    value={row.quantity}
                    type="number"
                    inputProps={{ min: "0", step: "1" }}
                    onChange={(e) => {
                      if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) {
                        handleInputChange(e, index);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    disabled
                    name="rowTotal"
                    value={row.rowTotal}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  <IconButton color="secondary" onClick={() => removeRow(index)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={() => toggleRowExpansion(index)}>
                    {expandedRow === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                  <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                      <Typography variant="h6" gutterBottom component="div">
                        {t('invoiceDetailPage.additionalDetails')}
                      </Typography>
                      <Table size="small" aria-label="additional-details">
                        <TableBody>
                          <TableRow>
                            <TableCell>{t('invoiceDetailPage.table.netUnitPrice')}</TableCell>
                            <TableCell>
                              <TextField
                                name="netPrice"
                                value={row.netPrice}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('invoiceDetailPage.table.taxRate')}</TableCell>
                            <TableCell>
                              <TextField
                                name="taxRate"
                                value={row.taxRate}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('invoiceDetailPage.table.bruttoUnitPrice')}</TableCell>
                            <TableCell>
                              <TextField
                                name="bruttoPrice"
                                value={row.bruttoPrice}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('invoiceDetailPage.table.discountPercentage')}</TableCell>
                            <TableCell>
                              <TextField
                                name="discountPercentage"
                                value={row.discountPercentage}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('invoiceDetailPage.table.discountAmount')}</TableCell>
                            <TableCell>
                              <TextField
                                name="discountAmount"
                                value={row.discountAmount}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </TableCell>
                          </TableRow>
                          {/* Add more fields as needed */}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
          <TableRow>
            <TableCell colSpan={5} style={{ textAlign: 'right' }}>
              {showAddRows && (
                <>
                  <Button variant="outlined" color="primary" onClick={addBoxRow} style={{ marginLeft: '10px' }}>
                    {t('invoiceDetailPage.buttons.addBoxRow')}
                  </Button>
                  <Button variant="outlined" color="primary" onClick={addMachineRow} style={{ marginLeft: '10px' }}>
                    {t('invoiceDetailPage.buttons.addMachineRow')}
                  </Button>
                  <Button variant="outlined" color="primary" onClick={addServiceRow} style={{ marginLeft: '10px' }}>
                    {t('invoiceDetailPage.buttons.addServiceRow')}
                  </Button>
                </>
              )}
              <IconButton color="primary" onClick={() => addRow()}>
                <CreateIcon /> {/* Manual entry */}
              </IconButton>
              <IconButton color="primary" onClick={() => setItemSelectionOpen(true)}>
                <ListIcon /> {/* Popup selection */}
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* Item Selection Popup */}
      <ItemSelectionPopup
        open={itemSelectionOpen}
        onClose={() => setItemSelectionOpen(false)}
        onAddItem={handleAddItem}
      />
    </TableContainer>
  );
};

export default InvoiceTable;

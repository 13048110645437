// pages/GuidingMachinePage/TodaysBookings.js

import React, { useState, useEffect } from 'react';
import {
    Box, Button, Paper, Typography, LinearProgress, IconButton,
} from '@mui/material';
import { Snackbar, Alert } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { fetchBookingsPerDate, createBooking, deleteBooking } from '../../services/guidingMachineService';
import { getOwnerByEmail, getAllOwners } from '../../services/ownerService';
import { getAllHorses, getHorsesByOwnerID } from '../../services/horseService';
import { fetchMachineSettings } from '../../services/MachineSettingsService';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete'; // Import DeleteIcon
import { useDateLocale } from '../../hooks/useDateLocale';  // Your custom hook
import AddIcon from '@mui/icons-material/Add';
import MachineBookingModal from '../../components/MachineBookingModal';
import GenericTableView from '../../components/GenericTableView'; // Assuming you have this component
import GenericCardView from '../../components/GenericCardView'; // Assuming you have this component
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useTheme, useMediaQuery } from '@mui/material';
import { usePreferences } from '../../context/UserPreferencesContext';

const TodaysBookings = () => {
    const { t } = useTranslation();  // Hook for translation
    const [bookings, setBookings] = useState([]);
    const { apiKey, userId, userEmail, userType, ownerId } = useAuth();
    const { organizationId, token: jwtToken } = useAuth();
    const [horseData, setHorseData] = useState([]);
    const [ownerData, setOwnerData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [deviceData, setDeviceData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [filterDate, setFilterDate] = useState(new Date());
    const [viewMode, setViewMode] = useState('table');
    const { devicePreferences, updateDevicePreferences } = usePreferences();
    const dateLocale = useDateLocale();
    const defaultStructure = {
        headers: [{
            key: 'start_time', label: t('GuidingMachinePage.todaysBookings.startTime'), display: { value: true, chipColor: false }
        }, {
            key: 'horse', label: t('GuidingMachinePage.todaysBookings.horseText'), display: { value: true, chipColor: false }
        }, {
            key: 'owner', label: t('GuidingMachinePage.todaysBookings.owner'), display: { value: true, chipColor: false }
        }, {
            key: 'managementType', label: t('GuidingMachinePage.todaysBookings.managementType'), display: { value: true, chipColor: false }
        }, {
            key: 'comment', label: t('GuidingMachinePage.todaysBookings.commentCard'), display: { value: true, chipColor: false }
        }, {
            key: 'deviceName', label: t('GuidingMachinePage.todaysBookings.deviceName'), display: { value: true, chipColor: false }
        }],
        rows: [],
    };
    const [structure, setStructure] = useState(defaultStructure);


    useEffect(() => {
        setLoading(true);
        try {
            const fetchBookings = async () => {
                try {
                    const owners = await getAllOwners(apiKey, organizationId, jwtToken);
                    const horses = await getAllHorses(apiKey, organizationId, jwtToken);
                    const devices = await fetchMachineSettings(apiKey, organizationId, jwtToken);
                    const response = await fetchBookingsPerDate(userId, filterDate.toISOString(), apiKey, organizationId, jwtToken);

                    setOwnerData(owners);
                    setHorseData(horses);
                    setDeviceData(devices);

                    setBookings(response);
                    setStructure(prevStructure => ({
                        ...prevStructure,
                        headers: prevStructure.headers,
                        rows: response.map(row => ({
                            id: row.id,
                            start_time: {
                                value: new Date(row.start_time).toLocaleTimeString(dateLocale),
                                icon: null
                            },
                            horse: {
                                value: horses.find(horse => horse.id === row.horse_id)?.name ?? '',
                                icon: null
                            },
                            owner: {
                                value: owners.find(owner => owner.id === row.owner_id) ? `${owners.find(owner => owner.id === row.owner_id).firstname} ${owners.find(owner => owner.id === row.owner_id).surname}` : '',
                                icon: null
                            },
                            managementType: {
                                value: row.whom,
                                icon: null
                            },
                            comment: {
                                value: row.description,
                                icon: null
                            },
                            deviceName: {
                                value: devices.find(device => device.id === row.device_id).machineType,
                                icon: null
                            },
                        }))
                    }));

                } catch (error) {
                    setErrorMessage(error.message);
                    setIsErrorOpen(true);
                }
            };
            fetchBookings();
        } catch (error) {
            setErrorMessage(error.message);
            setIsErrorOpen(true);
        } finally {
            setLoading(false);

        }
    }, [apiKey, showModal]);

    // Update local state when context updates
    useEffect(() => {
      setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);

    const handleDelete = async (bookingId) => {
        try {
            await deleteBooking(bookingId, apiKey, organizationId, jwtToken);
            setSuccessMessage(t('GuidingMachinePage.deleteBookingSuccess'));
            setIsSuccessOpen(true);
        } catch (error) {
            setErrorMessage(error.message);
            setIsErrorOpen(true);
        }
    }

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const toggleViewMode = () => {
        updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
    };

    const handleRowClick = (bookingId) => {
        //navigate(`/horses/${horseId}`);
    };

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 2 }}>
            <Box>
                <Typography variant="h4" gutterBottom>
                    {t('GuidingMachinePage.todaysBookings.heading')}
                </Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenModal}
                        startIcon={<AddIcon />}
                    >
                        {t('GuidingMachinePage.bookNewTimeButton')}
                    </Button>

                    <IconButton onClick={toggleViewMode} color="primary">
                        {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
                    </IconButton>
                </Box>
                {loading ? (
                    <>
                        <Typography variant="h6" gutterBottom>{t('GuidingMachinePage.Bookings.waitingText')}</Typography>
                        <LinearProgress />
                    </>
                ) : viewMode === 'table' ? (
                    <GenericTableView structure={structure} onClick={handleRowClick} />
                ) : (
                    <GenericCardView structure={structure} onClick={handleRowClick} />
                )}
            </Box>


            <Snackbar open={isErrorOpen} autoHideDuration={6000} onClose={() => setIsErrorOpen(false)}>
                <Alert onClose={() => setIsErrorOpen(false)} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={isSuccessOpen} autoHideDuration={6000} onClose={() => setIsSuccessOpen(false)}>
                <Alert onClose={() => setIsSuccessOpen(false)} severity="success">
                    {successMessage}
                </Alert>
            </Snackbar>
            <MachineBookingModal
                isOpen={showModal}
                onClose={handleCloseModal}
                devicesProps={deviceData}
                horsesProp={horseData}
            />
        </Paper>
    );

};

export default TodaysBookings;
import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DeliveryNoteAddress = ({ deliveryNoteData, handleInputChange }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">{t('deliveryNoteDetailPage.addressInformation')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="street"
          label={t("deliveryNoteDetailPage.address.street")}
          variant="outlined"
          value={deliveryNoteData.address?.street || ''}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="city"
          label={t("deliveryNoteDetailPage.address.city")}
          variant="outlined"
          value={deliveryNoteData.address?.city || ''}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="zipCode"
          label={t("deliveryNoteDetailPage.address.zipCode")}
          variant="outlined"
          value={deliveryNoteData.address?.zipCode || ''}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="country"
          label={t("deliveryNoteDetailPage.address.country")}
          variant="outlined"
          value={deliveryNoteData.address?.country || ''}
          onChange={handleInputChange}
        />
      </Grid>
    </Grid>
  );
};

export default DeliveryNoteAddress;

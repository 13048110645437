import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, IconButton, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { saveLocationSettings, updateLocationSettings } from '../services/LocationSettingsService';
import { useAuth } from '../context/AuthContext';

const LocationDialog = ({ open, onClose, initialData }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({ name: '', buildings: [] });
    const { apiKey, organizationId, token: jwtToken } = useAuth();
    const buildingTypes = [
        { value: 'warehouse', label: t('buildingTypes.warehouse') },
        { value: 'stable', label: t('buildingTypes.stable') },
        { value: 'ridingArena', label: t('buildingTypes.ridingArena') }
    ];
    

    useEffect(() => {
        if (initialData) {
            setFormData({
                ...initialData,
                buildings: initialData.buildings || [] // Ensure buildings is always an array
            });
        } else {
            setFormData({ name: '', buildings: [] });
        }
    }, [initialData]);

    const handleSave = async () => {
        try {
            if (formData.id) {
                await updateLocationSettings(formData.id, formData, apiKey, organizationId, jwtToken);
            } else {
                await saveLocationSettings(formData, apiKey, organizationId, jwtToken);
            }
            onClose(true); // Indicate success
        } catch (error) {
            console.error('Failed to save location:', error);
            onClose(false); // Indicate failure
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleBuildingChange = (index, name, value) => {
        const newBuildings = formData.buildings.map((building, i) =>
            i === index ? { ...building, [name]: value } : building
        );
        setFormData(prevState => ({
            ...prevState,
            buildings: newBuildings,
        }));
    };

    const addBuilding = () => {
        setFormData(prevState => ({
            ...prevState,
            buildings: [...prevState.buildings, { id: uuidv4(), name: '', type: { warehouse: false, stable: false, ridingArena: false } }]
        }));
    };

    const removeBuilding = (index) => {
        const newBuildings = formData.buildings.filter((_, i) => i !== index);
        setFormData(prevState => ({
            ...prevState,
            buildings: newBuildings,
        }));
    };

    const handleCheckboxChange = (index, type) => {
        const newBuildings = formData.buildings.map((building, i) =>
            i === index ? { ...building, type: { ...building.type, [type]: !building.type[type] } } : building
        );
        setFormData(prevState => ({
            ...prevState,
            buildings: newBuildings,
        }));
    };

    return (
        <Dialog open={open} onClose={() => onClose(null)} fullWidth maxWidth="sm">
            <DialogTitle>{initialData ? t('locations.editLocation') : t('locations.addLocation')}</DialogTitle>
            <DialogContent>
                <TextField
                    label={t('locations.name')}
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                />
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Button
                            startIcon={<AddCircle />}
                            onClick={addBuilding}
                            variant="outlined"
                            fullWidth
                        >
                            {t('locations.addBuilding')}
                        </Button>
                    </Grid>
                    {formData.buildings.map((building, index) => (
                        <React.Fragment key={building.id || index}>
                            <Grid item xs={5}>
                                <TextField
                                    label={t('locations.buildingName')}
                                    name="name"
                                    value={building.name}
                                    onChange={(e) => handleBuildingChange(index, e.target.name, e.target.value)}
                                    fullWidth
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                {buildingTypes.map(type => (
                                    <FormControlLabel
                                        key={type.value}
                                        control={
                                            <Checkbox
                                                checked={building.type[type.value]}
                                                onChange={() => handleCheckboxChange(index, type.value)}
                                                name={type.value}
                                            />
                                        }
                                        label={t(`locations.${type.label}`)}
                                    />
                                ))}
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton onClick={() => removeBuilding(index)}>
                                    <RemoveCircle />
                                </IconButton>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(null)}>{t('common.cancel')}</Button>
                <Button onClick={handleSave} disabled={!formData.name || formData.buildings.some(building => !building.name || Object.values(building.type).every(val => !val))}>
                    {t('common.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LocationDialog;

import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { calculateTax, calculateTotal } from './invoiceUtils';
import { fetchInvoiceById, saveInvoice, updateInvoice, downloadInvoicePdf, sendInvoicePdf } from '../../services/InvoiceService';
import { getAllOwners } from '../../services/ownerService';
import { getHorsesByOwnerID } from '../../services/horseService';
import { fetchPricingSettings } from '../../services/PricingService';
import { formatISO, add } from 'date-fns';
import { fetchSpecificOwnerBookings } from '../../services/guidingMachineService';
import { bookingStatisticsOwnerSpecific } from '../../services/bookingService';
import { createPayment, fetchPaymentByReference } from '../../services/PaymentsService';
import { fetchServiceTypes } from '../../services/serviceTypesService';
import useMapDocumentToInvoice from './useMapDocumentToInvoice';
import { usePermissions } from '../../context/PermissionsContext';

export const useInvoice = (invoiceId, apiKey, organizationId, jwtToken, userId, openSnackbar) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const state = location.state || {};
  const navigate = useNavigate();
  const [ownersWithHorses, setOwnersWithHorses] = useState([]);
  const [pricingOptions, setPricingOptions] = useState([]);
  const today = new Date();
  const defaultIssueDate = formatISO(today);
  const defaultDueDate = formatISO(add(today, { days: 14 }));
  const { entitlements } = usePermissions();
  const [paymentExists, setPaymentExists] = useState(false);
  const [paymentId, setPaymentId] = useState('');
  const initialPaymentDetail = {
    id: '',
    amount: '',
    date: '',
    payerName: '',
    payerContact: '',
    payerReference: '',
    paymentMethod: '',
    referenceId: '',
    referenceType: '',
    notes: ''
  };
  const initialInvoiceData = {
    id: '',
    recipientType: '',
    ownerId: '',
    companyName: '',
    cancel: false,
    creationMethod: 'manual',
    customerName: '',
    companyBRN: '',
    issueDate: defaultIssueDate,
    dueDate: defaultDueDate,
    totalAmount: 0,
    totalNetAmount: 0,
    taxAmount: 0,
    organizationId: '',
    reminderLevel: 0,
    reminderId: '',
    alreadyReminded: false,
    rows: [],
    address: {
      street: '',
      zipCode: '',
      city: '',
      country: '',
      email: '',
    },
  };
  const entitlement = entitlements.find(entitlement => entitlement.isActive) || {};
  const isEquistab = entitlement.internalName?.includes('equistab');
  const isErp = entitlement.internalName?.includes('erp');
  const [invoiceData, setInvoiceData] = useState(initialInvoiceData);
  const [paymentData, setPaymentData] = useState(initialPaymentDetail);
  const [isCancelled, setIsCancelled] = useState(false);
  const [showAddRows, setShowAddRows] = useState(false);
  useMapDocumentToInvoice(state, initialInvoiceData, setInvoiceData);

  const checkForExistingPayment = async () => {
    if (invoiceData.id) {
      try {
        const payments = await fetchPaymentByReference(apiKey, organizationId, jwtToken, invoiceData.id);
        if (payments.length > 0) {
          setPaymentExists(true);
          setPaymentId(payments[0].id);
        } else {
          setPaymentExists(false);
        }
      } catch (error) {
        console.error('Error checking for existing payment:', error);
        setPaymentExists(false);
      }
    }
  };

  const checkForCancellation = () => {
    if (invoiceData.cancel) {
      setIsCancelled(true);
    } else {
      setIsCancelled(false);
    }
  };

  useEffect(() => {
    checkForExistingPayment();
    checkForCancellation();
  }, [invoiceData.id, apiKey, organizationId, jwtToken]);

  const calculateTotals = () => {
    const totalAmount = calculateTotal(invoiceData.rows);
    const taxAmount = calculateTax(invoiceData.rows);

    setInvoiceData((prevData) => ({
      ...prevData,
      totalAmount: totalAmount,
      taxAmount: taxAmount,
      totalNetAmount: totalAmount - taxAmount,
    }));
  };

  useEffect(() => {
    if (invoiceId !== 'new') {
      const fetchData = async () => {
        setLoading(true);
        try {
          const data = await fetchInvoiceById(apiKey, organizationId, jwtToken, invoiceId, userId);
          setInvoiceData(data);
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [invoiceId, apiKey, organizationId, jwtToken, userId]);

  useEffect(() => {
    async function fetchAndSetOwners() {
      try {
        const ownersData = await getAllOwners(apiKey, organizationId, jwtToken);
        setOwnersWithHorses(ownersData);
      } catch (error) {
        console.error(error);
      }
    }
    fetchAndSetOwners();
  }, [apiKey, organizationId, jwtToken]);

  useEffect(() => {
    const fetchPricing = async () => {
      try {
        const pricingData = await fetchPricingSettings(apiKey, organizationId, jwtToken);
        setPricingOptions(pricingData);
      } catch (error) {
        console.error('Error fetching pricing settings:', error);
      }
    };
    fetchPricing();
  }, [apiKey, organizationId, jwtToken]);

  useEffect(() => {
    calculateTotals();
  }, [invoiceData.rows]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...invoiceData.rows];
    const row = updatedRows[index];

    row[name] = value;

    if (name === 'discountPercentage' || name === 'discountAmount') {
      const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
      let discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
      let discountAmount = parseFloat(row.discountAmount) || 0;

      if (name === 'discountPercentage') {
        discountAmount = parseFloat((bruttoPrice * discountPercentage).toFixed(2));
        row.discountAmount = discountAmount;
      } else if (name === 'discountAmount') {
        discountPercentage = parseFloat((discountAmount / bruttoPrice).toFixed(2));
        row.discountPercentage = (discountPercentage * 100).toFixed(2);
      }
    }

    if (name === 'quantity' || name === 'bruttoPrice' || name === 'discountPercentage' || name === 'discountAmount') {
      const quantity = parseFloat(row.quantity) || 0;
      const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
      const taxRate = parseFloat(row.taxRate) / 100 || 0;
      const discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
      const discountAmount = parseFloat(row.discountAmount) || 0;

      const discountedPrice = parseFloat((bruttoPrice - discountAmount).toFixed(2));
      const rowTotal = parseFloat((quantity * discountedPrice).toFixed(2));
      const netPrice = parseFloat((discountedPrice / (1 + taxRate)).toFixed(2));

      row.rowTotal = rowTotal;
      row.netPrice = netPrice;
    }

    setInvoiceData((prevData) => ({
      ...prevData,
      rows: updatedRows,
    }));

    calculateTotals();
  };

  const removeRow = (index) => {
    const updatedRows = [...invoiceData.rows];
    updatedRows.splice(index, 1);
    setInvoiceData({ ...invoiceData, rows: updatedRows });
    calculateTotals();
  };

  const addRow = (row) => {
    if (!row) {
      setInvoiceData({ ...invoiceData, rows: [...invoiceData.rows, { description: '', rowNumber: '', type: '', quantity: '', taxRate: '', bruttoPrice: '', netPrice: '', discountPercentage: '', discountAmount: '', rowTotal: '', itemId: '', organizationId: '' }] });
    } else {
      setInvoiceData({ ...invoiceData, rows: [...invoiceData.rows, row] });
    }
  };

  const saveOrUpdateInvoice = async (openSnackbar) => {
    setLoading(true);
    calculateTotals();
    try {
      let responseData;
      if (invoiceId !== 'new') {
        responseData = await updateInvoice(invoiceData, apiKey, organizationId, jwtToken, userId);
      } else {
        responseData = await saveInvoice(invoiceData, apiKey, organizationId, jwtToken, userId);
      }
      setInvoiceData(responseData);
      setLoading(false);
      openSnackbar('Invoice successfully saved!', 'success');
      navigate('/sales-process?tab=3');
      return true;
    } catch (err) {
      setLoading(false);
      setError(err.message);
      openSnackbar('Failed to save invoice', 'error');
      return false;
    }
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    const nameParts = name.split('.');
    if (nameParts.length === 2) {
      const [addressKey, field] = nameParts;
      setInvoiceData(prevData => ({
        ...prevData,
        [addressKey]: {
          ...prevData[addressKey],
          [field]: value,
        },
      }));
    }
  };

  const getHeaderAndAddressData = (selectedOwner) => {
    const headerAndAddress = {
      customerName: `${selectedOwner.firstname} ${selectedOwner.surname}`,
      companyName: selectedOwner.ownerType === 'company' ? selectedOwner.companyName : '',
      companyBRN: selectedOwner.ownerType === 'company' ? selectedOwner.companyBRN : '',
      recipientType: selectedOwner.ownerType,
      ownerAddress: {
        street: selectedOwner.address.street || '',
        zipCode: selectedOwner.address.zipCode || '',
        city: selectedOwner.address.city || '',
        country: selectedOwner.address.country || '',
        email: selectedOwner.email || '',
      }
    };

    return headerAndAddress;
  };

  const getRowsForBoxes = async (ownerId, pricingOptions, apiKey, organizationId, jwtToken) => {
    const horses = await getHorsesByOwnerID(ownerId, apiKey, organizationId, jwtToken);
    return horses.map(horse => {
      const horsePricing = pricingOptions.find(p => p.referenceId === horse.boxTypeId && p.referencePartition === 'Box');
      const defaultPricingValues = {
        taxRate: '0',
        bruttoPrice: '0',
        netPrice: '0',
        rowTotal: '0'
      };

      return {
        description: horse.name,
        quantity: 1,
        taxRate: horsePricing ? horsePricing.VAT : defaultPricingValues.taxRate,
        bruttoPrice: horsePricing ? horsePricing.bruttoPrice : defaultPricingValues.bruttoPrice,
        netPrice: horsePricing ? horsePricing.nettoPrice : defaultPricingValues.netPrice,
        rowTotal: horsePricing ? horsePricing.bruttoPrice : defaultPricingValues.rowTotal,
        type: horsePricing ? horsePricing.referencePartition : 'Box',
        organizationId: organizationId,
      };
    });
  };

  const getRowsForMachines = async (userId, defaultIssueDate, ownerId, apiKey, organizationId, jwtToken, pricingOptions) => {
    const ConvertedDate = new Date(defaultIssueDate);
    const selectedDate = new Date(ConvertedDate.getFullYear(), ConvertedDate.getMonth(), 1);
    const offset = selectedDate.getTimezoneOffset();
    const adjustedDate = new Date(selectedDate.getTime() - (offset * 60 * 1000));
    const date = adjustedDate.toISOString().slice(0, 10);
    const horses = await getHorsesByOwnerID(ownerId, apiKey, organizationId, jwtToken);
    const response = await fetchSpecificOwnerBookings(userId, date, ownerId, apiKey, organizationId, jwtToken);
    let combinedRows = [];

    if (response.bookingCounts && response.bookingCounts.length > 0) {
      const deviceUsage = response.bookingCounts[0];
      const machines = deviceUsage.machines;

      const machineHorseCounts = deviceUsage.bookings.reduce((acc, usage) => {
        if (usage.payed === "True") {
          const key = `${usage.machine_id}_${usage.horseId}`;
          acc[key] = (acc[key] || 0) + 1;
        }
        return acc;
      }, {});

      const machineHorseRows = Object.entries(machineHorseCounts).map(([key, count]) => {
        const [machineId, horseId] = key.split('_');
        const machine = machines.find(m => m.id === machineId);
        const horse = horses.find(h => h.id === horseId);
        const machinePricing = pricingOptions.find(p => p.referenceId === machineId && p.referencePartition === 'Machine');

        return {
          description: `${machine?.name || 'Unknown Machine'} used by ${horse?.name || 'Unknown Horse'}`,
          quantity: count,
          taxRate: machinePricing ? machinePricing.VAT : '0',
          bruttoPrice: machinePricing ? machinePricing.bruttoPrice : '0',
          netPrice: machinePricing ? machinePricing.nettoPrice : '0',
          rowTotal: machinePricing ? (machinePricing.bruttoPrice * count).toFixed(2) : '0',
          type: 'Device',
          organizationId: organizationId,
        };
      });

      combinedRows = [...combinedRows, ...machineHorseRows];
    }

    return combinedRows;
  };

  const getRowsforServices = async (userId, defaultIssueDate, ownerId, apiKey, organizationId, jwtToken, pricingOptions) => {
    let serviceRows = [];
    try {
      const ConvertedDate = new Date(defaultIssueDate);
      const selectedDate = new Date(ConvertedDate.getFullYear(), ConvertedDate.getMonth(), 1);
      const offset = selectedDate.getTimezoneOffset();
      const adjustedDate = new Date(selectedDate.getTime() - (offset * 60 * 1000));
      const date = adjustedDate.toISOString().slice(0, 10);
      const horses = await getHorsesByOwnerID(ownerId, apiKey, organizationId, jwtToken);
      const serviceTypes = await fetchServiceTypes(apiKey, organizationId, jwtToken);
      const response = await bookingStatisticsOwnerSpecific(userId, date, ownerId, apiKey, organizationId, jwtToken);
      const pricingOptions = await fetchPricingSettings(apiKey, organizationId, jwtToken);

      if (response && response.length > 0) {
        serviceRows = response[0].lessons.map(lesson => ({
          description: lesson.serviceType,
          quantity: lesson.bookings.length,
          taxRate: lesson.bookings[0].pricing ? lesson.bookings[0].pricing.VAT : '0',
          netPrice: lesson.bookings[0].pricing ? lesson.bookings[0].pricing.netPrice : '0',
          bruttoPrice: lesson.bookings[0].pricing ? lesson.bookings[0].pricing.unitPrice : '0',
          rowTotal: lesson.bookings[0].pricing ? (lesson.bookings[0].pricing.unitPrice * lesson.bookings.length).toFixed(2) : '0',
          type: 'Service',
          organizationId: organizationId,
        }));
      }

      horses.forEach(horse => {
        const horseServiceIds = horse.services.map(service => service.serviceId);
        const horseServiceTypes = serviceTypes.filter(serviceType => horseServiceIds.includes(serviceType.id));

        serviceRows = [...serviceRows, ...horseServiceTypes.map(serviceType => ({
          description: serviceType.name,
          quantity: 1,
          taxRate: pricingOptions.find(p => p.referenceId === serviceType.id && p.referencePartition === 'Service')?.VAT || '0',
          netPrice: pricingOptions.find(p => p.referenceId === serviceType.id && p.referencePartition === 'Service')?.nettoPrice || '0',
          bruttoPrice: pricingOptions.find(p => p.referenceId === serviceType.id && p.referencePartition === 'Service')?.bruttoPrice || '0',
          rowTotal: pricingOptions.find(p => p.referenceId === serviceType.id && p.referencePartition === 'Service')?.bruttoPrice || '0',
          type: 'Service',
          organizationId: organizationId,
        }))];
      });
    } catch (error) {
      console.error('Error getting rows for services:', error);
    }
    return serviceRows;
  };

  const addServiceRow = async () => {
    try {
      setLoading(true);
      const serviceRows = await getRowsforServices(userId, defaultIssueDate, invoiceData.ownerId, apiKey, organizationId, jwtToken, pricingOptions);
      setInvoiceData(prevData => ({
        ...prevData,
        rows: [...prevData.rows, ...serviceRows],
      }));
    } catch (error) {
      console.error('Error adding service row:', error);
    } finally {
      setLoading(false);
    }
  };

  const addBoxRow = async () => {
    try {
      setLoading(true);
      const boxRows = await getRowsForBoxes(invoiceData.ownerId, pricingOptions, apiKey, organizationId, jwtToken);
      setInvoiceData(prevData => ({
        ...prevData,
        rows: [...prevData.rows, ...boxRows],
      }));
    } catch (error) {
      console.error('Error adding box row:', error);
    } finally {
      setLoading(false);
    }
  };

  const addMachineRow = async () => {
    try {
      setLoading(true);
      const machineRows = await getRowsForMachines(userId, defaultIssueDate, invoiceData.ownerId, apiKey, organizationId, jwtToken, pricingOptions);
      setInvoiceData(prevData => ({
        ...prevData,
        rows: [...prevData.rows, ...machineRows],
      }));
    } catch (error) {
      console.error('Error adding machine row:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOwnerChange = (event, newValue, reason) => {
    if (reason === 'selectOption' && newValue) {
      setShowAddRows(isEquistab);
      const selectedOwner = ownersWithHorses.find(owner => owner.id === newValue.id);
      const { customerName, companyName, companyBRN, recipientType, ownerAddress } = getHeaderAndAddressData(selectedOwner);
      setInvoiceData(prevData => ({
        ...prevData,
        ownerId: newValue.id,
        customerName: newValue.label,
        companyName: companyName,
        companyBRN: companyBRN,
        recipientType: recipientType,
        address: ownerAddress,
      }));
    } else if (reason === 'clear') {
      setInvoiceData(prevData => ({
        ...prevData,
        ownerId: '',
        customerName: '',
        companyName: '',
        companyBRN: '',
        recipientType: '',
        address: {
          street: '',
          zipCode: '',
          city: '',
          country: '',
          email: '',
        }
      }));
      setShowAddRows(false);
    } else if (reason === 'create-option' || reason === 'input') {
      setShowAddRows(false);
      setInvoiceData(prevData => ({
        ...prevData,
        ownerId: '',
        customerName: newValue,
        companyName: '',
        companyBRN: '',
        recipientType: '',
        address: {
          street: '',
          zipCode: '',
          city: '',
          country: '',
          email: '',
        }
      }));
    }
  };

  const handleDownloadPdf = async (invoiceId) => {
    try {
      const pdfBlob = await downloadInvoicePdf(apiKey, organizationId, jwtToken, invoiceId, userId);
      const blobUrl = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `invoice-${invoiceData.invoiceNumber}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading invoice PDF:', error);
    }
  };

  const handleSendPdf = async (invoiceId) => {
    try {
      await sendInvoicePdf(apiKey, organizationId, jwtToken, invoiceId, userId);
    } catch (error) {
      console.error('Error sending invoice PDF:', error);
    }
  };

  const updatePaymentDataFromInvoice = () => {
    setPaymentData(prevPaymentData => ({
      ...prevPaymentData,
      amount: invoiceData.totalAmount || '',
      date: defaultIssueDate,
      payerName: invoiceData.customerName || '',
      payerContact: invoiceData?.address?.email || '',
      payerReference: invoiceData.id || '',
      paymentMethod: 'cash',
      referenceId: invoiceData.id || '',
      referenceType: 'Invoice',
      notes: invoiceData.id ? `Payment for Invoice ${invoiceData.id}` : ''
    }));
  };

  useEffect(() => {
    updatePaymentDataFromInvoice();
  }, [invoiceData]);

  const handleIncomingPayment = async () => {
    try {
      const response = await createPayment(paymentData, apiKey, organizationId, jwtToken);
      checkForExistingPayment();
      console.log('Payment created successfully:', response);
      openSnackbar('Incoming payment recorded', 'success');
    } catch (error) {
      console.error('Error creating payment:', error);
      openSnackbar('Failed to record payment', 'error');
    }
  };

  const handleOpenPayment = () => {
    navigate(`/payments/detail/${paymentId}`);
  };

  const handleCancel = async () => {
    try {
      const response = await updateInvoice({ ...invoiceData, cancel: true }, apiKey, organizationId, jwtToken, userId);
      setInvoiceData(response);
      openSnackbar('Invoice successfully cancelled', 'success');
      navigate('/sales-process?tab=3');
    } catch (error) {
      console.error('Error cancelling invoice:', error);
      openSnackbar('Failed to cancel invoice', 'error');
    }
  };

  return {
    invoiceData,
    ownersWithHorses,
    paymentExists,
    isCancelled,
    showAddRows,
    addBoxRow,
    addMachineRow,
    addServiceRow,
    handleOwnerChange,
    handleOpenPayment,
    handleDownloadPdf,
    handleIncomingPayment,
    handleSendPdf,
    setInvoiceData,
    calculateTotals,
    handleInputChange,
    handleAddressChange,
    handleCancel,
    saveOrUpdateInvoice,
    removeRow,
    addRow,
    loading,
    error,
  };
};

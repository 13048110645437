// src/pages/DocumentsDetailsPage/useDocumentDetails.js
import { useState, useEffect } from 'react';
import { fetchDocumentMetadata, deleteDocumentMetadata, scanDocument, scanItems } from '../../services/documentService';
import { useAuth } from '../../context/AuthContext'; // Assuming you have a context to provide these
import { useNavigate } from 'react-router-dom';

const useDocumentDetails = (documentId) => {
    const initialDocumentDetails = {
        id: '',
        name: '',
        documentType: '',
        description: '',
        blobUrl: '',
        orginalName: '',
        url: '',
        businessType: '',
        structuredData: '{}',
        structuredItemSuggestion: {"items": [],"categories": [],"measureUnits": [],},
        uploadDate: null,
    };
    const [documentDetails, setDocumentDetails] = useState(initialDocumentDetails);
    const navigate = useNavigate();
    const { apiKey, organizationId, token } = useAuth(); // Assuming these are provided by your auth context
    const [scanResult, setScanResult] = useState(null);
    const [isPageLoading, setIsPageLoading] = useState(true); // Separate loading state for page data
    const [isScanLoading, setIsScanLoading] = useState(false); // Separate loading state for scan operation
    const [openModal, setOpenModal] = useState(false);
    const handleClose = () => setOpenModal(false);
    const [itemSuggestionModalOpen, setItemSuggestionModalOpen] = useState(false);

    useEffect(() => {
        const fetchDocumentDetailsById = async () => {
            setIsPageLoading(true); // Start loading for page data
            try {
                const details = await fetchDocumentMetadata(documentId, apiKey, organizationId, token);
                setDocumentDetails(details);
            } catch (error) {
                console.error('Failed to fetch document details:', error);
            } finally {
                setIsPageLoading(false); // End loading for page data
            }
        };

        if (documentId) {
            fetchDocumentDetailsById();
        }
    }, [documentId, apiKey, organizationId, token]);

    const deleteDocument = async (documentId) => {
        try {
            await deleteDocumentMetadata(documentId, apiKey, organizationId, token);
            navigate('/documents');
        } catch (error) {
            console.error('Failed to delete document:', error);
        }
    }

    const downloadBase64File = (base64Data, fileName) => {
        const link = document.createElement('a');
        link.href = `data:${documentDetails.documentType};base64,${base64Data}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownload = () => {
        downloadBase64File(documentDetails.base64, documentDetails.name || documentDetails.originalName);
    };

    const handleItemScan = async () => {
        setIsScanLoading(true); // Start loading for scan operation
        try {
            const result = await scanItems(documentId, apiKey, organizationId, token);
        } catch (error) {
            console.error('Failed to scan items:', error);
        } finally {
            setIsScanLoading(false); // End loading for scan operation
        }
    }


    const handleScan = async () => {
        setIsScanLoading(true); // Start loading for scan operation
        try {
            const result = await scanDocument(documentId, apiKey, organizationId, token);
            const newDetails = await fetchDocumentMetadata(documentId, apiKey, organizationId, token);
            setDocumentDetails(newDetails);
        } catch (error) {
            console.error('Failed to scan document:', error);
        } finally {
            setIsScanLoading(false); // End loading for scan operation
        }
    }

    const handleShowStructuredData = async () => {
        try {
            let details = null
            //structuredData is json format, i need to check if it is empty
            if (!documentDetails.structuredData || JSON.stringify(documentDetails.structuredData) === '{}' || documentDetails.structuredData === null) {
                setIsPageLoading(true); // Re-use page loading state for fetching metadata again
                details = await fetchDocumentMetadata(documentId, apiKey, organizationId, token);
                setDocumentDetails(details);
            }
            setScanResult(details.structuredData);
            setOpenModal(true);
        } catch (error) {
            console.error('Failed to fetch structured data:', error);
        } finally {
            setIsPageLoading(false);
        }
    };

    const handleShowItemSuggestion = async () => {
        try {
            setItemSuggestionModalOpen(true);
        } catch (error) {
        }
    };


    const handleTransferClick = () => {
        if (documentDetails.businessType === 'expense') {
            navigate(`/expenses/detail/new`, { state: { fromDocument: documentDetails.structuredData } });
        } else if (documentDetails.businessType === 'horsepass') {
            const transferData = documentDetails.structuredData;
            transferData.documentId = documentDetails.id;
            transferData.documentName = documentDetails.name;
            navigate(`/horses/create/new`, { state: { fromDocument: transferData } });
        }
    }

    return {
        documentDetails,
        handleDownload,
        deleteDocument,
        handleTransferClick,
        handleScan,
        handleItemScan,
        scanResult,
        handleShowStructuredData,
        handleShowItemSuggestion,
        isPageLoading, // Expose the new loading state
        isScanLoading, // Expose the scan-specific loading state
        openModal,
        setItemSuggestionModalOpen,
        itemSuggestionModalOpen,
        handleClose
    };
};

export default useDocumentDetails;

import React, { useEffect } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import OrganizationInfo from '../components/OrgTabs/OrganizationInfo';
import FoodSettings from '../components/OrgTabs/FoodSettings/FoodSettings';
import MachineSettings from '../components/OrgTabs/MachineSettings';
import FerrySettings from '../components/OrgTabs/FerrySettings';
import VeterinarianSettings from '../components/OrgTabs/VeterinarianSettings';
import BoxSettings from '../components/OrgTabs/BoxSettings';
import PricingSettings from '../components/OrgTabs/PricingSettings';
import UserManagement from '../components/OrgTabs/UserManagement';
import DocumentSettings from '../components/OrgTabs/DocumentSettings';
import TaxSettings from '../components/OrgTabs/TaxSettings';
import InventorySettings from '../components/OrgTabs/InventorySettings';
import TeacherSettings from '../components/OrgTabs/TeacherSettings/TeacherSettings';
import ServiceTypes from '../components/OrgTabs/ServiceOfferings/ServiceTypes';
import SmartThingsSettings from '../components/OrgTabs/SmartThingsSettings';
import LocationsSettings from '../components/OrgTabs/LocationsSettings';

import { usePermissions } from '../context/PermissionsContext'; // Import PermissionsContext

const OrganizationSettings = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const { entitlements } = usePermissions(); // Get entitlements from PermissionsContext
  const [value, setValue] = React.useState(0);

  // Extract features from entitlements
  const features = entitlements
    .map((entitlement) => entitlement.features)
    .flat()
    .filter((feature) => feature.status === 'enabled');

  const hasFeature = (featureName) => {
    return features.some((feature) => feature.name === featureName);
  };

  const tabs = [
    { label: t('OrganizationSettings.Tabs.OrganizationInformation'), component: <OrganizationInfo />, requiredFeatures: [] },
    { label: t('OrganizationSettings.Tabs.FoodSettings'), component: <FoodSettings />, requiredFeatures: ['food-management'] },
    { label: t('OrganizationSettings.Tabs.MachineSettings'), component: <MachineSettings />, requiredFeatures: ['machine-management'] },
    { label: t('OrganizationSettings.Tabs.FerrySettings'), component: <FerrySettings />, requiredFeatures: ['ferry-management'] },
    { label: t('OrganizationSettings.Tabs.VeterinarianSettings'), component: <VeterinarianSettings />, requiredFeatures: ['veterinarian-management'] },
    { label: t('OrganizationSettings.Tabs.BoxSettings'), component: <BoxSettings />, requiredFeatures: ['box-management'] },
    { label: t('OrganizationSettings.Tabs.PricingSettings'), component: <PricingSettings />, requiredFeatures: ['pricing-management'] },
    { label: t('OrganizationSettings.Tabs.UserManagement'), component: <UserManagement />, requiredFeatures: ['user-management'] },
    { label: t('OrganizationSettings.Tabs.DocumentSettings'), component: <DocumentSettings />, requiredFeatures: ['document-management'] },
    { label: t('OrganizationSettings.Tabs.TaxSettings'), component: <TaxSettings />, requiredFeatures: ['tax-management'] },
    { label: t('OrganizationSettings.Tabs.InventorySettings'), component: <InventorySettings />, requiredFeatures: ['inventory-management'] },
    { label: t('OrganizationSettings.Tabs.TeacherSettings'), component: <TeacherSettings />, requiredFeatures: ['teacher-management'] },
    { label: t('OrganizationSettings.Tabs.ServiceTypes'), component: <ServiceTypes />, requiredFeatures: ['service-management'] },
    { label: t('OrganizationSettings.Tabs.SmartThingsSettings'), component: <SmartThingsSettings />, requiredFeatures: ['smart-things-management'] },
    { label: t('OrganizationSettings.Tabs.Locations'), component: <LocationsSettings />, requiredFeatures: ['location-management'] },
  ];

  const availableTabs = tabs.filter(
    (tab) => tab.requiredFeatures.length === 0 || tab.requiredFeatures.every(hasFeature)
  );

  // Update `value` based on the URL query parameter on initial render
  useEffect(() => {
    const tab = query.get('tab');
    if (tab !== null && availableTabs[parseInt(tab)]) {
      setValue(parseInt(tab));
    } else {
      setValue(0); // Default to the first available tab
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // Update the URL query parameter when `value` changes
  useEffect(() => {
    const currentTab = query.get('tab');
    if (currentTab !== `${value}`) {
      query.set('tab', value);
      navigate(`${location.pathname}?${query.toString()}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        {t('OrganizationSettings.Title')}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="organization settings tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          {availableTabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {availableTabs[value] && availableTabs[value].component}
    </Box>
  );
};

export default OrganizationSettings;
